import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/commons/Footer';
import MobileFooter from './components/commons/FooterMovil';
import Info from './components/Info';
import InfoMovil from './components/InfoMovil';
import NavigationBar from './components/commons/Nav';
import Payment from './components/Payment';
import Process from './components/Process';
import ProcessMovil from './components/ProcessMovil';
import Review from './components/Review';
import Somos from './components/Somos';
import Terms from './pages/Terms';
import Tiempos from './components/Tiempos';
import Cotizar from './pages/Cotizar';
import Documents from './pages/Documents';
import Faqs from './pages/Faqs';
import Gracias from './pages/Gracias';
import Index from './pages/Index';
import Blog from './pages/blog/Blog';
import Post from './pages/blog/Post';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_8_7makj1.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Router>
      <div className="App bg-navbar">
        <NavigationBar />
        <Routes>
          <Route path="/" element={<>
            <Index />
            <Somos />
            <Info />
            <InfoMovil />
            <Process />
            <ProcessMovil />
            <Payment />
            <Review />
            <Tiempos />
          </>} />
          <Route path="/Index" element={<Index />} />
          <Route path='/cotizar' element={<Cotizar />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/documents' element={<Documents />} />
          <Route path='/gracias' element={<Gracias />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="blog/post/:id" element={<Post />} />
        </Routes>
        <Footer />
        <MobileFooter />
      </div>
    </Router>
  );
}

export default App;
