import { create } from 'zustand';
import moment from 'moment';

export const useBlogStore = create((set) => ({
    posts: [],
    error: null,
    loading: true,
    fetchPosts: async () => {
        set({ loading: true });
        try {
            const postsResponse = await fetch(`${window.location.origin}/post/post.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                cache: 'no-store'
            });

            if (!postsResponse.ok) {
                throw new Error('No data found');
            }

            const postsData = await postsResponse.json();
            const postDetailsPromises = postsData.map(
                (post) => post.ref ? fetch(`${window.location.origin}/${post.ref}`).then((res) => res.json()) : Promise.resolve({})
            );

            const postDetails = await Promise.all(postDetailsPromises);
            const postsWithDate = postsData.map((post, i) => ({
                ...post,
                details: postDetails[i],
                date: moment(post.date, 'YYYY-MM-DD')
            }));
            const postsWithDateSorted = postsWithDate.sort((a, b) => b.date - a.date);

            set({
                posts: postsWithDateSorted,
                data: postsData,
                error: null,
                loading: false
            });
        } catch (error) {
            set({ error: error.message || "Error al obtener los posts", loading: false });
        }
    },
    findById: (id) => {
        const { posts } = useBlogStore.getState();
        return posts.find((post) => post.id === id);
    },
}));
