import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const QuoteCotizar = () => {
    const navigate = useNavigate();

    const envioDesdeRef = useRef(null);
    const hastaRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 2; // Now only 2 steps

    const [formData, setFormData] = useState({
        vehiculo: '',
        envioDesde: '',
        hasta: '',
        nombreApellido: '',
        email: '',
        telefono: '',
    });

    const [errors, setErrors] = useState({
        vehiculo: true,
        envioDesde: true,
        hasta: true,
        nombreApellido: true,
        email: true,
        telefono: true,
    });

    const [touched, setTouched] = useState({
        vehiculo: false,
        envioDesde: false,
        hasta: false,
        nombreApellido: false,
        email: false,
        telefono: false,
    });

    useEffect(() => {
        if (!window.google) return;

        initAutocomplete(envioDesdeRef.current, 'envioDesde');
        initAutocomplete(hastaRef.current, 'hasta');
    }, []);

    const initAutocomplete = (element, fieldName) => {
        if (!element) return;

        const autocomplete = new window.google.maps.places.Autocomplete(element, { types: ['geocode'], componentRestrictions: { country: ['us', 'pr', 'gt', 'sv', 'ni', 'hn'] } });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                setErrors(prev => ({ ...prev, [fieldName]: true }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            } else {
                setFormData(prev => ({ ...prev, [fieldName]: place.formatted_address }));
                setErrors(prev => ({ ...prev, [fieldName]: false }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            }
        });
    };
    const validateField = (name, value) => {
        const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}-?\d{0,4}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        switch (name) {
            case 'vehiculo':
                return value.trim() !== '';
            case 'nombreApellido':
                return value.trim() !== '';
            case 'telefono':
                return phoneRegex.test(value);
            case 'email':
                return emailRegex.test(value);
            default:
                return false;
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
    let isValid = true;

    if (name === "telefono") {
        // Elimina todos los caracteres que no sean dígitos
        
        const digits = value.replace(/\D/g, "");

        // Limita la cantidad de dígitos a 15, permitiendo hasta 4 caracteres adicionales por los guiones
        const limitedDigits = digits.slice(0, 15);

        let formattedNumber = '';

        // Formatea el número con paréntesis y guiones
        if (limitedDigits.length <= 3) {
            formattedNumber = `(${limitedDigits}`;
        } else if (limitedDigits.length <= 6) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3)}`;
        } else if (limitedDigits.length <= 10) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
        } else {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}-${limitedDigits.slice(10)}`;
        }
        // Limita la longitud total del campo a 16 caracteres (paréntesis, guiones y dígitos)
        formattedNumber = formattedNumber.substring(0, 19);

        isValid = validateField(name, formattedNumber);
        setFormData(prevData => ({ ...prevData, [name]: formattedNumber }));
    } else {
        isValid = validateField(name, value);
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }

    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
    };
    
    const nextStep = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const isCurrentStepValid = () => {
        switch (currentStep) {
            case 1:
                return !errors.envioDesde && !errors.hasta && !errors.vehiculo;
            case 2:
                return !errors.nombreApellido && !errors.email && !errors.telefono;
            default:
                return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { vehiculo, envioDesde, hasta, nombreApellido, email, telefono } = formData;
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fields: {
                        TITLE: nombreApellido,
                        EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                        PHONE: [{ VALUE: telefono, VALUE_TYPE: 'WORK' }],
                        UF_CRM_1470686551: envioDesde,
                        UF_CRM_1470686597: hasta,
                        SOURCE_ID: "Solicitud de cotización Prautotransport",
                        UF_CRM_1486142337: vehiculo,
                        ASSIGNED_BY_ID: "2", // Jose Garavito ID
                    }
                }),
            };

            const response = await fetch('https://colcargollc.bitrix24.com/rest/11056/6jv7knoe8e09jy8d/crm.lead.add.json', options);
            if (!response.ok) throw new Error('Network response was not ok');
            navigate('/gracias');

            resetFormStates();
        } catch (error) {
            console.error("There was an error adding the lead:", error);
        }
    };

    const resetFormStates = () => {
        setFormData({
            vehiculo: '',
            envioDesde: '',
            hasta: '',
            nombreApellido: '',
            email: '',
            telefono: '',
        });
        setErrors({
            vehiculo: true,
            envioDesde: true,
            hasta: true,
            nombreApellido: true,
            email: true,
            telefono: true,
        });
        setTouched({
            vehiculo: false,
            envioDesde: false,
            hasta: false,
            nombreApellido: false,
            email: false,
            telefono: false,
        });
    };

    const StepIndicator = () => (
        <div className="step-indicator-wrapper mb-4 w-100 mx-auto">
        <div className={`step ${currentStep === 1 ? 'active' : ''}`}>
            <div className="step-icon">{currentStep === 1 ? '●' : '○'}</div>
            <div className="step-label">Información del envío</div>
        </div>
        <div className={`step-line ${currentStep === 2 ? 'full' : ''}`}></div>
        <div className={`step ${currentStep === 2 ? 'active' : ''}`}>
            <div className="step-icon">{currentStep === 2 ? '●' : '○'}</div>
            <div className="step-label">Información personal</div>
        </div>
    </div>
      );

      return (
        <div className="px-5 my-3 ">
            <StepIndicator></StepIndicator>
            <form onSubmit={handleSubmit} className='mb-5'>
                {currentStep === 1 && (
                    <div className='w-75 mx-auto'>
                        <div className="form-group mb-2">
                            <label htmlFor='envioDesde' className='fw-medium mb-2'>Origen</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.envioDesde && touched.envioDesde ? 'is-invalid' : ''}`}
                                type="text"
                                ref={envioDesdeRef}
                                placeholder='Direccion de origen'
                                onFocus={() => setTouched({ ...touched, envioDesde: true })}
                            />
                            {errors.envioDesde && touched.envioDesde && <div className="invalid-feedback">Por favor, seleccione una ubicación válido.</div>}
                        </div>
    
                        <div className="form-group mb-2">
                            <label htmlFor='hasta' className='fw-medium mb-2'>Destino</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.hasta && touched.hasta ? 'is-invalid' : ''}`}
                                type="text"
                                ref={hastaRef}
                                placeholder='Direccion de destino'
                                onFocus={() => setTouched({ ...touched, hasta: true })}
                            />
                            {errors.hasta && touched.hasta && <div className="invalid-feedback">Por favor, seleccione una ubicación válido.</div>}
                        </div>
    
                        <div className="form-group mb-2">
                            <label htmlFor='vehiculo' className='fw-medium mb-2'>Marca, Modelo y Año</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.vehiculo && touched.vehiculo ? 'is-invalid' : ''}`}
                                type="text"
                                name="vehiculo"
                                onChange={handleChange}
                            />
                            {errors.vehiculo && touched.vehiculo && <div className="invalid-feedback">Este campo es obligatorio.</div>}
                        </div>
                    </div>
                )}
    
                {currentStep === 2 && (
                    <div className='w-50 mx-auto'>
                        <div className="form-group mb-2">
                            <label htmlFor='nombreApellido' className='fw-medium mb-2'>Nombre y apellido</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.nombreApellido && touched.nombreApellido ? 'is-invalid' : ''}`}
                                type="text"
                                name="nombreApellido"
                                onChange={handleChange}
                                onBlur={() => setTouched({ ...touched, nombreApellido: true })}
                            />
                            {errors.nombreApellido && touched.nombreApellido && <div className="invalid-feedback">Este campo es obligatorio.</div>}
                        </div>
    
                        <div className="form-group mb-2">
                            <label htmlFor='email' className='fw-medium mb-2'>Email</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={() => setTouched({ ...touched, email: true })}
                            />
                            {errors.email && touched.email && <div className="invalid-feedback">Por favor, introduzca un email válido.</div>}
                        </div>
    
                        <div className="form-group mb-2">
                            <label htmlFor='telefono' className='fw-medium mb-2'>Teléfono</label>
                            <input
                                className={`form-control rounded-4 mb-3 ${errors.telefono && touched.telefono ? 'is-invalid' : ''}`}
                                type="tel"
                                name="telefono"
                                value={formData.telefono}
                                onChange={handleChange}
                                onBlur={() => setTouched({ ...touched, telefono: true })}
                            />
                            {errors.telefono && touched.telefono && <div className="invalid-feedback">Por favor, introduzca un teléfono válido.</div>}
                        </div>
                    </div>
                )}
    
                <div className="text-center mt-4">
                    {currentStep < totalSteps && (
                        <button
                            type="button"
                            className='btn btn-btn-outline-success custom-button '
                            onClick={nextStep}
                            disabled={!isCurrentStepValid()}>
                            Continuar
                        </button>
                    )}
                    {currentStep === totalSteps && (
                        <button
                            type="submit"
                            className='btn btn-outline-success custom-button '
                            disabled={!isCurrentStepValid()}>
                            Solicitar cotización
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
    
};

export default QuoteCotizar;
