import React, { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

function Gracias() {
    useEffect(() => {
        if (window.gtag) {
          window.gtag('event', 'conversion', {'send_to': 'AW-866906506/lPSJCK-c_poZEIrjr50D'});
        }
      }, []);
    return (
        <div className="">
            <Row className='text-poppins g-0' id='gracias'>
                <Col md={6} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#DF495A' }}>
                    <Image src="/images/cotizar.png" alt="Descripción de la imagen" className="img-fluid" style={{ maxHeight: '75%' }} />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                    <div className='w-50 mx-auto'>
                    <h1 className="mt-5 mb-3 ">Gracias por confiar en nosotros</h1>
                    <p className=''>Estamos trabajando para preparar una cotización personalizada que se ajuste a tus necesidades específicas. Pronto recibirás todos los detalles para que puedas tomar una decisión informada. Tu satisfacción es nuestra prioridad</p>
                    <div className='text-center'>                    
                     <a href="/" className='btn button-gracias text-center g-0 my-3'>Volver al Inicio</a>
                     </div>
                     <div className='text-center mb-3'>
                     <a href="/terms" className="link-secondary link-underline link-underline-opacity-0 text-center">Terminos & Condiciones</a>
                     </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Gracias;
