import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div fluid className="bg-dark py-3 text-white text-poppins d-none d-md-block" id='footer'>
            <Row className="justify-content-center g-0 mt-3">
                <Col xs={12} md={2} >
                    <Image src="/images/logofooter.svg" fluid alt="Logo" className='ms-5 px-3' />
                </Col>
                <Col xs={12} md={4}>
                    <h5 className='text-white'>San Juan, Puerto Rico.</h5>
                    <p className='text-secondary'>Garlas LLC. Ave. Boulevard, W-7, Ste. 2, Levittown, Toa Baja, PR 00949</p>
                    <p className="text-secondary">
                        <a href='mailto:info@prautotransport.com' className='link-secondary link-underline link-underline-opacity-0'>info@prautotransport.com</a> <br /> Todos los derechos reservados <br /> © {currentYear}
                    </p>
                    <a href="https://www.instagram.com/prautotransport/" className=''>
                        <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
                    </a>
                </Col>
                <Col xs={12} md={2} >
                    <h5 >Tampa, Florida.</h5>
                    <p className='text-secondary'>16484 Northdale Oaks Dr Tampa, FL 33624</p>

                </Col>
                <Col xs={12} md={1} className='gap-3 d-flex flex-column'>
                    <a href="/" className="link-light link-underline link-underline-opacity-0">Home</a>
                    <a href="/faqs" className="d-block link-light link-underline link-underline-opacity-0">FAQ's</a>
                    <a href="/cotizar" className="link-light link-underline link-underline-opacity-0">Cotizar</a>
                    <a href="/terms" className="d-block link-light link-underline link-underline-opacity-0">Terminos & Condiciones</a>
                </Col>
                <Col xs={12} md={2}>
                    <a className='btn btn-outline-success custom-button' href='/cotizar'>Cotizar</a>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;
