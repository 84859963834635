import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

function MobileFooter() {
  const currentYear = new Date().getFullYear();
  return (
    <div fluid className="bg-dark text-white py-3 d-block d-md-none text-poppins" id='footermovil'> {/* Mostrar solo en móviles */}
      <Row className='g-0'>
        <Col xs={12} className=''>
        <Image src="/images/logofooter.svg" fluid alt="Logo" className='mt-3 ms-4 ' />
        </Col>
        <Col xs={12}>
        <h5 className='text-white  mt-3 px-4'>San Juan, Puerto Rico.</h5>
        <p className='text-secondary px-4'>Garlas LLC. Ave. Boulevard, W-7, Ste. 2, Levittown, Toa Baja, PR 00949</p>
        </Col>
        <Col xs={6} className='px-4'>
        <h5 className='text-white mt-3'>Tampa,Florida.</h5>
        <p className='text-secondary'>16484 Northdale Oaks DrTampa,FL 33624</p>
          <p className="text-secondary">
            <a href='mailto:info@prautotransport.com' className='link-secondary link-underline link-underline-opacity-0'>info@prautotransport.com</a> <br /> Todos los derechos reservados <br /> © {currentYear}
          </p>
        <a href="https://www.instagram.com/prautotransport/">
            <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
        </a>
        </Col>
      </Row>
      <Row className='mt-5 text-center g-0'>
        <Col xs={6}>
        <a href="/" className="link-light link-underline link-underline-opacity-0">Home</a>
         <a href="/faqs" className="d-block link-light link-underline link-underline-opacity-0 mt-3">FAQ's</a>
        </Col>
        <Col xs={6}>
        <a href="#" className="link-light link-underline link-underline-opacity-0">Cotizar</a>
        <a href="/" className="d-block link-light link-underline link-underline-opacity-0 mt-3">Blog</a>
       
        </Col>
        <Row className=' mt-3 g-0'>
            <Col xs={12} className='text-center'>
            <a href="/terms" className="link-light link-underline link-underline-opacity-0">Legal</a>
            </Col>
            <Col xs={12} className='mt-3 text-center'>
            <a href="/terms" className="link-light link-underline link-underline-opacity-0 text-center">Terminos & Condiciones</a>
            </Col>
        </Row>
      </Row>
      <Row  className="text-center my-3 g-0">
        <Col xs={11} className="text-center mt-3">
        <a className='custom-buttonf btn  btn-outline-success' href='#'>
         Cotizar
        </a>
        </Col>
      </Row>
    </div>
  );
}

export default MobileFooter;
