import { Button, Image } from 'react-bootstrap';
import { usePost } from '../../hook/usePost';
import BlogCarousel from './BlogCarousel';
import { useState, useEffect, useMemo } from 'react';
import * as motion from "motion/react-client"

const Blog = () => {
  const { posts, loading, error } = usePost();
  const [postsCards, setPostsCards] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const postsMapAll = useMemo(() => posts.map((post) => ({
    id: post.id,
    title: post.title,
    resume: post.resume,
    cardImgPath: post.cardImgPath
  })), [posts]);

  useEffect(() => {
    const postsToCards = postsMapAll.slice(0, 3);
    setPostsCards(postsToCards);

  }, [postsMapAll]);

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error al cargar los posts</p>;
  }

  const handleShowAll = () => {
    setPostsCards(postsMapAll);
    setDisabled(true);
  };

  return (
    <>
      <Image src='/images/blog/blog_banner_1.png' className='w-100'></Image>
      <motion.section
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.75 }}
        className='d-flex container w-100 bg-green-light-cu p-lg-5 py-lg-5 py-5 flex-column gap-5' id="blog">

        <BlogCarousel posts={postsCards} size="large" justify="center" />
        <div className='d-flex justify-content-end'>
          <Button style={{ backgroundColor: "#cf2437", borderColor: "#cf2437" }} className={` px-4 py-1 rounded-1 ` + (disabled ? 'd-none' : '')} onClick={handleShowAll} >Ver Todos</Button>
        </div>

      </motion.section>
    </>
  );
}

export default Blog;