import { useEffect } from 'react';
import { useBlogStore } from '../store/blogStore';
export const usePost = () => {
    const { posts, error, loading, fetchPosts, findById } = useBlogStore();

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    return { posts, error, loading, fetchPosts, findById };
};